import React from "react"
import ConsentToCookiesContext from "../context/ConsentToCookiesContext"
import Map from "./map"

const Contact = () => (
  <ConsentToCookiesContext.Consumer>
    {cookieConsent => (
      <section className="contact clearfix">
        <span className="next-section" id="anfahrt-und-kontakt"></span>
        <h2>Anfahrt &amp; Kontakt</h2>
        <div className="contact-wrapper">
          <div className="address">
            <address>
              Kremp und Blumen
              <br />
              Hochdorferstr. 7<br />
              79232 March
            </address>
            <p>
              <strong>
                Deine Bestellung nehmen wir gerne telefonisch entgegen.
              </strong>
            </p>
            <a href="tel:004976654499" className="icon-phone">
              +49&nbsp;7665&nbsp;4499
            </a>
            <a href="mailto:mail@kremp-und-blumen.de" className="icon-mail">
              mail@kremp-und-blumen.de
            </a>
            <a
              className="icon-location get-directions"
              href="https://goo.gl/maps/zfWBUcXiTGQd2cXT9"
              target="_blank"
              rel="noopener noreferrer"
            >
              Route berechnen
            </a>
          </div>
          {cookieConsent.consent && <Map />}
        </div>
      </section>
    )}
  </ConsentToCookiesContext.Consumer>
)

export default Contact
