import React from "react"

const Stage = () => (
  <section className="promotion">
    <div className="image">
      <div className="promotion-heading">
        <h3>Advent</h3>
        <h2>bei Kremp</h2>
      </div>
    </div>
    <div className="content clearfix">
      <h2>Advents&shy;ausstellung in Laden und Scheune</h2>
      <p className="lead">
        Samstag, 23. November 8:30–20 Uhr
        <br />
        Sonntag 24. November 12–17 Uhr
      </p>
      <ul>
        <li>
          Ausstellung besonderer Adventskränze und weihnachtlicher Werkstücke
        </li>
        <li>Stimmungsvolle Zithermusik, Glühwein und Punsch</li>
        <li>Am Sonntag mit Foodtruck BUDA·BEST, ungarische Baumstriezel</li>
      </ul>
      <p>Wir freuen uns auf Euch!</p>
    </div>
  </section>
)

export default Stage
